<template>
  
  <div id="main">
    
    <router-view />
    
    <div id="loading" v-if="this.$store.state.loadingState">
      <img :src="loadingImg"/>
    </div>
  </div>  
</template>

<script>
export default {
  name: "App",
  data(){
    return{
      loadingImg :require("@/assets/images/loading.gif"),
    }
  }
};
</script>

<style>
html,body{
  font-size: 12px;
}
body::-webkit-scrollbar {
  display: none;
}
html::-webkit-scrollbar {
  display: none;
}
.page-container {
  background-color: #202020 !important;
  color: white !important;
}
#loading {
  clear: both;
  z-index: 98;
  width: 100%;
  height: 100vh;
  background: rgb(0,0,0,0.7);
  top:0 !important;
  position: absolute;
}
#loading img{
  clear: both;
  z-index: 99;
  top:45% !important;
  left:45% !important;
  height: 100px;
  width: 100px;
  position: absolute;

}
</style>
