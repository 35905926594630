<template>
  <div class="mini-slider-content">
    <br />
    <h3>EĞİTMENLERİMİZ</h3>
    <swiper
      :slides-per-view="5"
      :space-between="5"
      :navigation="false"
      :pagination="false"
      :mousewheel="true"
      :keyboard="true"
    >
      <swiper-slide class="mini-slider-item" v-for="egt in this.egitmenList" :key="egt">
        <router-link
          :to="{
            name: 'EgitmenDetay',
            params: { seourl: this.$gFunc.convertSeoToUrl(egt.ad + '-' + egt.soyad), eid: egt.id },
          }"
          ><img style="max-height: 275px !important;" :src="'kaynak/resimler/egitmenler/' + egt.imagePath"
        /></router-link>
      </swiper-slide>
      <swiper-slide class="mini-slider-item">
        <router-link
          :to="{
            name: 'Egitmenler',
          }"
          ><img :src="tum_egitmen_img"
        /></router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      tum_egitmen_img: "kaynak/resimler/base/tum-egitim-gorsel.png",
      egitmenList: [],
    };
  },
  mounted() {
    this.onLoadTeachers();
  },
  methods: {
    onLoadTeachers() {
      this.$axios
        .get("/api/dashboard/get-teachers")
        .then((response) => {
          response.data.forEach((element) => {
            this.egitmenList.push(element);
          });
        })
        .catch((error) => {
          console.log("onLoadTeachers Error :>> ", error);
        });
    },
  },
  computed: {},
};
</script>
<style scoped>
.mini-slider-content {
  margin: 0 5% 5% 5%;
}

.mini-slider-content h3 {
  margin: 0 0 5px 0;
  font-size: 16px;
  color: rgb(155, 155, 155);
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

.mini-slider-item {
}
.mini-slider-item img {
  width: 100%;
  max-height: 400px;
}
</style>

<!-- <template>
  <div class="title-box"> 
    EĞİTİMLERİMİZ 
  </div>  
  
</template>

<script>
export default {

}
</script>

<style>
.title-box{
  font-size: 16px;
}
</style> -->
