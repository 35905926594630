// import axios from "axios";

// let domain = "212.98.224.188";
// let port = "6002";
// let isSSL = true;



let apiUrlFull = "https://api.kultursanatakademi.com";
//let apiUrlFull = "https://localhost:5002";

import axios from "axios";

// let domain = "212.98.224.188";
// let port = "5354";
// let isSSL = false;

var instance = axios.create({
  baseURL: apiUrlFull
  //(isSSL ? "https://" : "http://") + domain + ":" + port,
  
});

instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    console.log("axiosErro :>> " + error);
    // if (error.response.status === 401) {
    //   console.log("axios err 401");
    //   // stored.commit("authentication/logout", router);
    // }
    // return Promise.reject(error);
  }
);

export default {
  install: function (Vue) {
    Vue.config.globalProperties.$axios = instance;
  },
};
