<template>
  <div class="red-band">
    <!-- <div class="col-4 band-item"></div>
    <div class="col-2 band-item"></div>
    <div class="col-2 band-item"></div>
    <div class="col-2 band-item"></div>
    <div class="col-2 band-item"></div> -->
    <div class="row justify-content-md-center">
      <div class="col-sm-3 band-item band-first">
        <p>
          Alanında en iyilerden<br />
          120+ eğitim alın <br />sizde başarıya ulaşın.
        </p>
      </div>
     
     
      <div class="col-sm-2 band-item band-second">
        <p>
          Farklı alanlarda her ay yeni eğitimler ve periyodik dönemlerle yeni kategoriler içinden
          onlarca eğitim sizleri bekliyor.
        </p>
      </div>
      
      <div class="col-sm-2 band-item band-second">
        <p>
          Katılım için herhangi bir özel yetenek gerekmeksizin sadece öğrenmeye ve keşfetmeye olan isteğiniz yeterli.
        </p>
      </div>
      
      <div class="col-sm-2 band-item band-second" style="font-size: 13.5px;">
        <p>
          Siz de yeni bir beceri kazanmak, alanınızda derinlemesine bilgi edinmek veya merak ettiğiniz konular hakkında daha fazla bilgi sahibi olmak istiyorsanız, doğru adrestesiniz.
        </p>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {};
</script>

<style scoped>
.red-band {
  width: 100%;
  padding: 0 5%;
  background-color: red;
  margin-bottom: 1%;
}

.red-band .band-item {
  height: 140px;
  margin: 1% 0%;
}
.red-band .band-first {
  font-size: 24px;
  padding: 1% 0%;
  text-align: center;
}
.red-band .band-second {
  text-align: center;
  background: rgba(0, 0, 0, 0.3);
  padding: 0% 1%;
  margin: 1%;


  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
</style>

<!--  
   <div
   class="row"
   style="
     margin: 0px;
     padding: 0px;
     background-color: rgb(227, 1, 15);
     width: 100%;
     height: 200px;
   "
 >
   <div class="col-sm-4 spell-box spell-content">
     <div class="">
       <p>
         Alanında en iyilerden<br />
         120+ eğitim alın <br />sizde başarıya ulaşın.
       </p>
     </div>
   </div>
   <div class="col-sm-2 radius-box">
     <div class="radius-content">
       <p>
         Farklı alanlarda her ay yeni eğitimler<br />
         ve periyodik dönemlerle yeni <br />
         kategoriler içinden onlarca eğitim <br />
         sizleri bekliyor.
       </p>
     </div>
   </div>
   <div class="col-sm-2 radius-box">
     <div class="radius-content">
       <p>
         Farklı alanlarda her ay yeni eğitimler<br />
         ve periyodik dönemlerle yeni <br />
         kategoriler içinden onlarca eğitim <br />
         sizleri bekliyor.
       </p>
     </div>
   </div>
   <div class="col-sm-2 radius-box">
     <div class="radius-content">
       <p>
         Farklı alanlarda her ay yeni eğitimler<br />
         ve periyodik dönemlerle yeni <br />
         kategoriler içinden onlarca eğitim <br />
         sizleri bekliyor.
       </p>
     </div>
   </div>
 </div>-->
