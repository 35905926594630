<template>
  <v-layout id="inspire">
    <v-overlay :value="drawer" z-index="4"> </v-overlay>
    <v-navigation-drawer v-model="drawer" app clipped hide-overlay style="top: 10%" :style="{ zIndex: 6 }">
      <v-list dense>
        <v-list-item
          ><router-link :to="{ name: 'Egitmenler' }" class=""><span>Eğitmenler </span></router-link>
        </v-list-item>
        <v-list-item
          ><router-link :to="{ name: 'Egitimler' }" class=""><span>Egitimler </span></router-link>
        </v-list-item>
        <v-list-item
          ><router-link :to="{ name: 'Blogs' }" class=""><span>Blog </span></router-link>
        </v-list-item>
        <v-list-item
          ><router-link :to="{ name: 'Belgeler' }" class=""><span>Belgelerimiz </span></router-link>
        </v-list-item>
        <v-list-item
          ><router-link :to="{ name: 'Basvuru' }" class=""><span>Başvuru Yap </span></router-link>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark color="black">
      <v-app-bar-nav-icon v-show="isMobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img @click="this.onDashboardClick" style="cursor: alias" :src="imgPath" width="100" heigth="50" contain></v-img>
      <template id="menuLinks" class="d-sm-none d-md-flex" style="text-underline-offset: 3px">
        <router-link :to="{ name: 'Egitmenler' }" class="ms-15"><span>Eğitmenler </span></router-link>
        <router-link :to="{ name: 'Egitimler' }" class="ms-4"><span>Egitimler</span></router-link>
        <router-link :to="{ name: 'Blogs' }" class="ms-4"><span>Blog</span></router-link>
        <router-link :to="{ name: 'Belgeler' }" class="ms-4"><span>Belgelerimiz</span></router-link>
        <router-link :to="{ name: 'Basvuru' }" class="ms-4"><span>Başvuru Yap</span></router-link>
        
      </template>
      <v-spacer class="d-sm-none d-md-flex"></v-spacer>
      <div v-show="!this.$store.getters.isAuthentication">
        <router-link :to="{ name: 'Login' }" class="me-4 btn btn-danger btn-sm" style="text-decoration: none !important"
          ><span>Giriş Yap </span></router-link
        >
      </div>
      <div v-show="this.$store.getters.isAuthentication">
        <router-link :to="{ name: 'OgrenciProfilim' }" class="me-4 btn btn-dark btn-sm" style="text-decoration: none !important; font-size: 12px;"
          ><span>Profilime Git </span></router-link
        >
      </div>
    </v-app-bar>
  </v-layout>
</template>

<!-- <template>
  <v-layout id="header">
    <v-app-bar>
      <v-img class="mx-5" @click="this.onDashboardClick" style="cursor: alias" :src="imgPath" max-width="300" contain></v-img>

      <template id="menuLinks" class="d-sm-none d-md-flex">
        <router-link :to="{ name: 'Egitmenler' }" class="ms-15"><span>Eğitmenler </span></router-link>        
        <router-link :to="{ name: 'Egitimler' }" class="ms-4"><span>Egitimler</span></router-link>
        <router-link :to="{ name: 'Blogs' }" class="ms-4"><span>Blog</span></router-link>
        <router-link :to="{ name: 'Basvuru' }" class="ms-4"><span>Başvuru Yap</span></router-link>
      </template>
      <v-spacer class="d-sm-none d-md-flex"></v-spacer>

      <div v-show="!this.$store.getters.isAuthentication">
        <router-link :to="{ name: 'Login' }" class="me-4 btn btn-danger btn-sm" style="text-decoration: none !important"><span>Giriş Yap </span></router-link>
      </div>
      <v-menu v-show="this.$store.getters.isAuthentication">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-account-circle" v-bind="props" v-show="this.$store.getters.isAuthentication"></v-btn>
        </template>

        <v-list>
          <v-list-item>           
            <v-list-item-title style="cursor: pointer" @click="this.onProfilimClick"> Profilim</v-list-item-title>
            <v-list-item-title style="cursor: pointer" @click="this.onLogout"> Çıkış Tap</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </v-layout>
</template> -->

<script>
import { useDisplay } from "vuetify";
export default {
  setup() {},
  data() {
    return {
      isMobile: false,
      drawer: false,
      imgPath: require("@/assets/images/logo.png"),
      loginLoop: false,
    };
  },
  mounted() {
    const { mobile } = useDisplay();
    if (mobile.value) this.isMobile = true;
  },
  methods: {
    onDashboardClick() {
      this.$router.push({ name: "Dashboard" });
    },
    onLogout() {
      this.$store.commit("logoutUser");
    },
    onProfilimClick() {
      if (this.$store.getters.getUserType == 1) this.$router.push({ name: "OgrenciDashboard" });
      else this.$router.push({ name: "JuriDashboard" });
    },
  },
};
</script>

<style scoped>
#header {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
}
a:link {
  text-decoration: underline !important;
}
a {
  color: white !important;
}
</style>
