import { createStore } from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
// import routed from "@/plugins/router";
var ls = new SecureLS({ isCompression: false });

const storeData = createStore({
  state() {
    return {
      tokenValue: undefined,
      loginUser: undefined,
      loadingState: false,
    };
  },
  getters: {
    getTokenValue: (state) => state.tokenValue,
    isAuthentication: (state) => {
      const aa = state.tokenValue != undefined;
      return aa;
    },
    getLoginUser: (state) => state.loginUser,
    getUserType: (state) => {
      if (state.loginUser != undefined) {
        return state.loginUser.logintype_id;
      } else {
        return 0;
      }
    },
  },  
  mutations: {
    loginUser(state, data) {
      state.tokenValue = data.token;
      state.loginUser = data;
    },
    logoutUser(state) {
      state.tokenValue = undefined;
      state.loginUser = undefined;
      window.location.reload();
    },
    changeLoading(state, status) {
      console.log('state.loadingState :>> ', state.loadingState,this.state.loadingState);
      state.loadingState = status;
      console.log('state.loadingState :>> ', state.loadingState,this.state.loadingState);
    },
  },
  computed:{},
  plugins: [
    createPersistedState({
      key: "kultursanatakademi",
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});

export default storeData;
