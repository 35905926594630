<template>
  <div class="mini-slider-content">
    <br />
    <h3>YENİ VE POPÜLER EĞİTİMLER</h3>
    <swiper
      :slides-per-view="5"
      :space-between="5"
      :navigation="false"
      :pagination="false"
      :mousewheel="true"
      :keyboard="true"
    >
      <swiper-slide class="mini-slider-item" style="max-height: 275px !important ;" v-for="egt in this.egitimList" :key="egt">
        <router-link
          :to="{
            name: 'EgitimDetay',
            params: { seourl: this.$gFunc.convertSeoToUrl(egt.baslik), eid: egt.id },
          }"
          ><img :src="'kaynak/resimler/egitimler/' + egt.imagePath"
        /></router-link>
      </swiper-slide>
      <swiper-slide class="mini-slider-item">
        <router-link
          :to="{
            name: 'Egitimler',
          }"
          ><img  :src="tum_egitim_img"
        /></router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "swiper/vue";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      tum_egitim_img: "kaynak/resimler/base/tum-egitmen-gorsel.png",
      egitimList: [],
    };
  },
  mounted() {
    this.onLoadEducation();
  },
  methods: {
    onLoadEducation() {
      this.$axios
        .get("/api/dashboard/get-educations")
        .then((response) => {
          response.data.forEach((element) => {            
            this.egitimList.push(element);
          });
        })
        .catch((error) => {
          console.log("onLoadEducation Error :>> ", error);
        });
    },
    // getEducationImg(imgName);
    // {
    //   return imgName != null
    //     ? "../FileContent/Images/Base/" + imgName
    //     : require("@/assets/FileContent/Images/Base/egitim-gorsel.png");
    // }
    // getEducationAllImg();
    // {
    //   return require("@/FileContent/Images/Base/tum-egitim-gorsel.png");
    // }
  },
};
</script>
<style>
.mini-slider-content {
  margin: 0 5% 5% 5%;
}

.mini-slider-content h3 {
  margin: 0 0 5px 0;
  font-size: 16px;
  color: rgb(155, 155, 155);
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
}

.mini-slider-item {
}
.mini-slider-item img {
  width: 100%;
  max-height: 400px;
}
</style>

<!-- <template>
    <div class="title-box"> 
      EĞİTİMLERİMİZ 
    </div>  
    
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  .title-box{
    font-size: 16px;
  }
  </style> -->
