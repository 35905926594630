import { createRouter, createWebHashHistory } from "vue-router";
import stored from "@/plugins/store";
const routes = [
  {
    name: "Dashboard",
    path: "/",
    component: () => import("@/views/DashboardPage"),
    props: true,
  },
  {
    //Login
    name: "Login",
    path: "/login",
    component: () => import("@/views/LoginPage"),
    props: true,
  },
  {
    //Egitmenler
    name: "Egitmenler",
    path: "/egitmenler",
    component: () => import("@/views/EgitmenlerPage"),
    props: true,
  },
  {
    //EgitmenDetay
    name: "EgitmenDetay",
    path: "/egitmenler/:seourl/:eid",
    component: () => import("@/views/EgitmenDetay"),
    props: true,
  },

  {
    //Egitimler
    name: "Egitimler",
    path: "/egitimler",
    component: () => import("@/views/EgitimlerPage"),
    props: true,
  },
  {
    //EgitimDetay
    name: "EgitimDetay",
    path: "/egitimler/:seourl",
    component: () => import("@/views/EgitimDetay"),
    props: true,
  },
  {
    //Blogs
    name: "Blogs",
    path: "/blog",
    component: () => import("@/views/BlogPage"),
    props: true,
  },
  {
    //Belgeler
    name: "Belgeler",
    path: "/documents",
    component: () => import("@/views/SertifikalarPage"),
    props: true,
  },
  {
    //BlogDetay
    name: "BlogDetay",
    path: "/blog/:bid/:seourl",
    component: () => import("@/views/BlogDetayPage"),
    props: true,
  },
  {
    //Basvuru
    name: "Basvuru",
    path: "/basvurular",
    component: () => import("@/views/BasvuruPage"),
    props: true,
  },
  {
    //SSSPage
    name: "SSSPage",
    path: "/sikca-sorulan-sorular",
    component: () => import("@/views/SSSPage"),
    props: true,
  },
  {
    //GizlilikForm
    name: "GizlilikForm",
    path: "/gizlilik-sozlesmesi",
    component: () => import("@/views/GizlilikForm"),
    props: true,
  },
  {
    //UyelikForm
    name: "UyelikForm",
    path: "/uyelik-sozlesmesi",
    component: () => import("@/views/UyelikForm"),
    props: true,
  },

  /* Öğrenci Router Alanı */
  {
    //OgrenciDashboard
    name: "OgrenciDashboard",
    path: "/actor",
    component: () => import("@/views/Ogrenci/OgrenciDashboard"),
    props: true,
  },
  {
    //OgrenciEgitimlerim
    name: "OgrenciEgitimlerim",
    path: "/actor/educations",
    component: () => import("@/views/Ogrenci/OgrenciEgitimlerim"),
    props: true,
  },
  {
    //OgrenciSertifikalar
    name: "OgrenciSertifikalar",
    path: "/actor/certifications",
    component: () => import("@/views/Ogrenci/OgrenciSertifikalar"),
    props: true,
  },
  {
    //OgrenciProfilim
    name: "OgrenciProfilim",
    path: "/actor/profile",
    component: () => import("@/views/Ogrenci/OgrenciProfilim"),
    props: true,
  },
  
  {
    //OgrenciEgitimDetay
    name: "OgrenciEgitimDetay",
    path: "/actor/educations-detail/:eid",
    component: () => import("@/views/Ogrenci/OgrenciEgitimDetay"),
    props: true,
  },
  /* Öğrenci Router Alanı */

  /* Juri Router Alanı */
  {
    //Dashboard
    name: "JuriDashboard",
    path: "/juri",
    component: () => import("@/views/Juri/JuriDashboard"),
    props: true,
  },
  {
    //NewOgrenci
    name: "NewOgrenci",
    path: "/juri/new-actor-create",
    component: () => import("@/views/Juri/NewOgrenciCreate"),
    props: true,
  },
  {
    //JuriOgrenciler
    name: "JuriOgrenciler",
    path: "/juri/list-actors",
    component: () => import("@/views/Juri/JuriActors"),
    props: true,
  },
  /* Juri Router Alanı */
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  // const authNotRoutes = ["Login", "Dashboard"];
  const isAuth = stored.getters.isAuthentication;
  const loginType = stored.getters.getUserType;
  // console.log("to :>> ", to);
  // console.log("from :>> ", from);
  // console.log("next :>> ", next);
  console.log("isAuth :>> ", isAuth);
  console.log("loginType :>> ", loginType);
  // console.log("path control :>> ", to.fullPath.includes("/egitmenler"));
  if (isAuth == false && !to.fullPath.includes("/actor") && !to.fullPath.includes("/juri")) next();
  else if (isAuth == false && (to.fullPath.includes("/actor") || to.fullPath.includes("/juri"))) next({ name: "Login" });
  else if (isAuth == true && loginType == 1 && !to.fullPath.includes("/juri")) next();
  else if (isAuth == true && loginType == 2 && !to.fullPath.includes("/actor")) next();
  else next({ name: "Login" });

  // else {
  //  if(loginType=="1" && t )
  // }
  // if (!isAuth && authNotRoutes.indexOf(to.name) > -1) next();
  // else if (!isAuth && authNotRoutes.indexOf(to.name) < 0)
  //   next({ name: "Login" });
  // else if (isAuth && authNotRoutes.indexOf(to.name) > -1)
  //   next({ name: "Dashboard" });
  // else next();
});

export default router;
