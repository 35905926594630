<template>
  <div class="slider-content">
    <div v-on:click="this.lastVideo" class="slider-left-button" style="z-index: 1">
      <v-icon>mdi-chevron-left</v-icon>
    </div>
    <video
      id="player-overlay"
      class="player-overlay"
      :autoplay="true"
      :muted="true"
      loop
      @contextmenu="this.onMouseDown"
      :src="this.videoSrcPath"
    ></video>

    <div v-on:click="this.nextVideo" class="slider-right-button" style="z-index: 1">
      <v-icon>mdi-chevron-right</v-icon>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
export default {
  mounted() {},
  created() {
    this.getSliders();
    // this.videoSrcPath = this.videoList[0];
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  data() {
    return {
      videoIndex: 0,
      isMobile: false,
      videoSrcPath: this.getSliderVideo(null),
      videoList: reactive([]),
    };
  },

  methods: {
    getSliderVideo(fileName) {      
      return fileName != null && fileName != undefined
        ? "kaynak/slider/" + fileName
        : "kaynak/slider/base/slider-1.mp4";
    },
    getSliders() {
      this.$axios
        .get("/api/dashboard/get-slider")
        .then((response) => {
          response.data.forEach((element) => {
            const path = this.getSliderVideo(element.videoPath);            
            this.videoList.push(path);
          });
        })
        .catch((error) => {
          console.log("error :>> ", error);
        });
    },
    onMouseDown(e) {
      e.preventDefault();
    },
    onResize() {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },

    nextVideo() {
      if (this.videoIndex == this.videoList.length - 1) {
        this.videoIndex = 0;
      } else {
        this.videoIndex++;
      }

      this.videoSrcPath = this.videoList[this.videoIndex];
    },
    lastVideo() {
      if (this.videoIndex == 0) {
        this.videoIndex = this.videoList.length - 1;
      } else {
        this.videoIndex--;
      }
      this.videoSrcPath = this.videoList[this.videoIndex];
    },
  },
};
</script>

<style scoped>
.slider-content {
  background-color: black;
}

@media only screen and (max-device-width: 767px) {
  /* Mobile */
  .slider-content {
    margin-top: 20%;
  }
  .player-overlay {
    width: 100%;
  }

  .slider-left-button {
    position: absolute;
    left: 0%;
    top: 20%;
    font-size: 30px;
  }
  .slider-right-button {
    position: absolute;
    right: 0%;
    top: 20%;
    font-size: 30px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  /* Styles */
  .slider-content {
    margin-top: 8.3%;
  }
  .player-overlay {
    width: 100%;
  }

  .slider-left-button {
    position: absolute;
    left: 2%;
    top: 40%;
    font-size: 50px;
  }
  .slider-right-button {
    position: absolute;
    right: 2%;
    top: 40%;
    font-size: 50px;
  }
}

@media only screen and (min-device-width: 1024px) {
  /* Styles */
  .slider-content {
    margin-top: 4%;
  }
  .player-overlay {
    width: 100%;
    height: 90vh;    
  }

  .slider-left-button {
    position: absolute;
    left: 2%;
    top: 40%;
    font-size: 50px;
  }
  .slider-right-button {
    position: absolute;
    right: 2%;
    top: 40%;
    font-size: 50px;
  }
}
</style>
