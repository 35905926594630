<template>
  <v-layout id="inspire">
    <v-overlay :value="drawer" z-index="4"> </v-overlay>
    <!--Mobil - Login olmuş-->
    <v-navigation-drawer v-model="drawer" app clipped hide-overlay style="top: 10%" >
      <v-list dense>
        <v-list-item
          ><router-link :to="{ name: 'OgrenciProfilim' }" class=""><span>Profilim </span></router-link>
        </v-list-item>
        <v-list-item
          ><router-link :to="{ name: 'OgrenciEgitimlerim' }" class=""><span>Eğitimlerim </span></router-link>
        </v-list-item>
        <v-list-item
          ><router-link :to="{ name: 'OgrenciSertifikalar' }" class=""><span>Sertifikalarım </span></router-link>
        </v-list-item>
        <hr/>
        <v-list-item-title style="cursor: pointer;text-decoration: underline;" class="ms-4" @click="this.onLogout"> Çıkış Yap</v-list-item-title>
        
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app dark color="black">
      <v-app-bar-nav-icon v-show="isMobile" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img @click="this.onDashboardClick" style="cursor: alias" :src="imgPath" width="100" heigth="50" contain></v-img>
      <template id="menuLinks" class="d-sm-none d-md-flex" style="text-underline-offset: 3px">
        <!--Large - Login olmuş-->        
        <router-link :to="{ name: 'OgrenciEgitimlerim' }" class="ms-3"><span>Eğitimlerim </span></router-link>
        <router-link :to="{ name: 'OgrenciSertifikalar' }" class="ms-3"><span>Sertifikalarım </span></router-link>        
        <router-link :to="{ name: 'OgrenciProfilim' }" class="ms-4"><span>Bilgilerim </span></router-link>
            
      </template>
      <v-spacer class="d-sm-none d-md-flex"></v-spacer>
      <div v-show="!this.$store.getters.isAuthentication">
        <router-link :to="{ name: 'Login' }" class="me-4 btn btn-danger btn-sm" style="text-decoration: none !important"
          ><span>Giriş Yap </span></router-link
        >
      </div>
      <div>
        <v-menu v-if="!isMobile" v-show="this.$store.getters.isAuthentication">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-account-circle" v-bind="props" v-show="this.$store.getters.isAuthentication"></v-btn>
          </template>

          <v-list style="min-height: 0px !important; max-height: 10% !important;margin: 0 !important;padding: 0 !important;">
            <v-list-item style="max-height: 10px;">
              <v-list-item-title style="cursor: default">
                {{ this.$store.getters.getLoginUser.name }}
                {{ this.$store.getters.getLoginUser.surname }}
                {{ this.$store.getters.getLoginUser.logintype_id }}
              </v-list-item-title>
              <hr/>
            </v-list-item>
            <v-list-item >
              <v-list-item-title style="cursor: pointer;" @click="this.onProfilimClick"> Profilim</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title style="cursor: pointer;" @click="this.onLogout"> Çıkış Tap</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
  </v-layout>
</template>

<!-- <template>
    <v-layout id="header">
      <v-app-bar>
        <v-img class="mx-5" @click="this.onDashboardClick" style="cursor: alias" :src="imgPath" max-width="300" contain></v-img>
  
        <template id="menuLinks" class="d-sm-none d-md-flex">
          <router-link :to="{ name: 'Egitmenler' }" class="ms-15"><span>Eğitmenler </span></router-link>        
          <router-link :to="{ name: 'Egitimler' }" class="ms-4"><span>Egitimler</span></router-link>
          <router-link :to="{ name: 'Blogs' }" class="ms-4"><span>Blog</span></router-link>
          <router-link :to="{ name: 'Basvuru' }" class="ms-4"><span>Başvuru Yap</span></router-link>
        </template>
        <v-spacer class="d-sm-none d-md-flex"></v-spacer>
  
        <div v-show="!this.$store.getters.isAuthentication">
          <router-link :to="{ name: 'Login' }" class="me-4 btn btn-danger btn-sm" style="text-decoration: none !important"><span>Giriş Yap </span></router-link>
        </div>
        <v-menu v-show="this.$store.getters.isAuthentication">
          <template v-slot:activator="{ props }">
            <v-btn icon="mdi-account-circle" v-bind="props" v-show="this.$store.getters.isAuthentication"></v-btn>
          </template>
  
          <v-list>
            <v-list-item>           
              <v-list-item-title style="cursor: pointer" @click="this.onProfilimClick"> Profilim</v-list-item-title>
              <v-list-item-title style="cursor: pointer" @click="this.onLogout"> Çıkış Tap</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
    </v-layout>
  </template> -->

<script>
import { useDisplay } from "vuetify";
export default {
  setup() {},
  data() {
    return {
      isMobile: false,
      drawer: false,
      imgPath: require("@/assets/images/logo.png"),
      loginLoop: false,
    };
  },
  mounted() {
    const { mobile } = useDisplay();
    if (mobile.value) this.isMobile = true;
  },
  methods: {
    onDashboardClick() {
      this.$router.push({ name: "Dashboard" });
    },
    onLogout() {
      this.$store.commit("logoutUser");
    },
    onProfilimClick() {
      if (this.$store.getters.getUserType == 1) this.$router.push({ name: "OgrenciDashboard" });
      else this.$router.push({ name: "JuriDashboard" });
    },
  },
};
</script>

<style scoped>
#header {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
}
a:link {
  text-decoration: underline !important;
}
a {
  color: white !important;
}
</style>
