<template>
  <div class="register-band">
    <p>
      <span> Eşsiz aktörlerin ve eğitmenlerin deneyimlerine sende katıl </span
      >
      <router-link class="btn btn-sm" style="margin-top: 0px" :to="{name:'Basvuru'}"><strong>Hemen Sende Katıl >> </strong></router-link>    
    </p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.register-band {
  width: 100%;
  height: 3%;
  background-color: #343532;
  border-bottom: 1px solid red;
}
.register-band p {
  text-align: center;
  font-size: 20px;
}
.register-band p a {
  text-align: end;
  font-size: 25px;
  margin-left: 5%;  
}
</style>
