import { createApp } from "vue";
import store from "./plugins/store";
import router from "./plugins/router";
import vuetify from "./plugins/vuetify";
import axios from "./plugins/axios";
import globalFunc from "./plugins/globalfunc";
import head from './plugins/seo';


// import Vue3CanvasVideoPlayer from 'vue3-canvas-video-player';

import App from "./App.vue";
import appHeader from "@/components/layout/appHeader";
import appSlider from "@/components/layout/appSlider";
import appTeachers from "@/components/layout/appTeachers";
import appRedBand from "@/components/layout/appRedBand";
import appEducations from "@/components/layout/appEducations";
import appRegisterNowBand from "@/components/layout/appRegisterNowBand";
import appFooter from "@/components/layout/appFooter";

import ogrHeader from "@/components/ogrenci/ogrHeader";
import ogrBlackBand from "@/components/ogrenci/ogrBlackBand";

import juriHeader from "@/components/juri/juriHeader";

import "@/assets/css/custom.css"
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";


// import 'vue3-canvas-video-player/dist/style.css';

const app = createApp(App);
app.use(store);
app.use(router);
app.use(vuetify);
app.use(axios);
app.use(head);

// app.use(Vue3CanvasVideoPlayer,{});

app.component("app-header", appHeader);
app.component("app-slider", appSlider);
app.component("app-teachers", appTeachers);
app.component("app-redband", appRedBand);
app.component("app-education", appEducations);
app.component("app-registerband", appRegisterNowBand);
app.component("app-footer", appFooter);

app.component("ogr-header", ogrHeader);
app.component("ogr-blackband", ogrBlackBand);

app.component("juri-header", juriHeader);

app.config.globalProperties.$gFunc = globalFunc;

app.mount("#app");
