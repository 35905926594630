<template>
  <v-layout id="header">
    <v-app-bar>
      <v-img class="mx-5" @click="this.onDashboardClick" style="cursor: alias" :src="imgPath" max-width="300" contain></v-img>

      <template id="menuLinks" class="d-sm-none d-md-flex">
        <router-link :to="{ name: 'NewOgrenci' }" class="ms-5"><span>Yeni Oyuncu Kaydet </span></router-link>
        <router-link :to="{ name: 'JuriOgrenciler' }" class="ms-3"><span>Oyuncu Listem </span></router-link>
      </template>
      <v-spacer class="d-sm-none d-md-flex"></v-spacer>

      <div v-show="!this.$store.getters.isAuthentication">
        <router-link :to="{ name: 'Login' }" class="me-4 btn btn-danger btn-sm" style="text-decoration: none !important"><span>Giriş Yap </span></router-link>
      </div>
      <v-menu v-show="this.$store.getters.isAuthentication">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-account-circle" v-bind="props" v-show="this.$store.getters.isAuthentication"></v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title style="cursor: pointer" @click="this.onProfilimClick">
              Profilim
              <!-- {{ this.$store.getters.getLoginUser.name }}
              {{ this.$store.getters.getLoginUser.surname }} -->
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="cursor: pointer" @click="this.$router.push({ name: 'NewOgrenci' })"> Yeni Oyuncu</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="cursor: pointer" @click="this.onLogout"> Çıkış Tap</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      imgPath: require("@/assets/images/logo.png"),
      loginLoop: false,
    };
  },
  methods: {
    onDashboardClick() {
      this.$router.push({ name: "JuriDashboard" });
    },
    onLogout() {
      this.$store.commit("logoutUser");
    },
    onProfilimClick() {
      this.$router.push({ name: "JuriDashboard" });
    },
  },
};
</script>

<style>
#header {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 13px;
}
a:link {
  text-decoration: underline !important;
}
a {
  color: white !important;
}
</style>
