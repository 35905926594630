<template>
  <div class="blackband">
    <h3>Eğitim İçeriğim</h3>
  </div>
</template>

<script>
export default {};
</script>

<style>
.blackband {
  height: 10%;
  background-color: black;
}
</style>
