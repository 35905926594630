<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <h4><img :src="logoPath" style="max-width: 80%;" /></h4>
      </div>

      <div class="row">
        <div class="col col-lg-3 footer-row-item">
          <p class="footer-row-title">KATEGORİLER</p>
          <ul>
            <li v-for="obj in kategoriList" :key="obj">
              {{ obj.ad }}
            </li>
          </ul>
        </div>
        <div class="col col-lg-3 footer-row-item">
          <p class="footer-row-title">EĞİTMENLER</p>
          <ul>
            <li v-for="obj in egitmenList" :key="obj">
              <router-link
                :to="{
                  name: 'EgitmenDetay',
                  params: {
                    seourl: this.$gFunc.convertSeoToUrl(obj.ad + '-' + obj.soyad),
                    eid: obj.id,
                  },
                }"
              >
                {{ obj.ad }} {{ obj.soyad }}
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col col-lg-3 footer-row-item">
          <p class="footer-row-title">EĞİTİMLER</p>
          <ul>
            <li v-for="obj in egitimlerList" :key="obj">
              <router-link
                :to="{
                  name: 'Egitimler',
                  params: { seourl: this.$gFunc.convertSeoToUrl(obj.baslik), eid: obj.id },
                }"
                >{{ obj.baslik }}</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col col-lg-3 footer-row-item">
          <p class="footer-row-title">HAKKIMIZDA</p>
          <ul>
            <li><router-link :to="{ name: 'SSSPage' }">Sık Sorulan Sorular</router-link></li>
            <li><router-link :to="{ name: 'GizlilikForm' }">Gizlilik Sözleşmesi</router-link></li>
            <li><router-link :to="{ name: 'UyelikForm' }">Üyelik Sözleşmesi</router-link></li>
            <li><router-link :to="{ name: 'Basvuru' }">Başvuru Yap</router-link></li>
          </ul>
          <p class="footer-row-title">SOSYAL MEDYA</p>
          <ul>
            <li><a href="https://www.facebook.com/kultursanat.akademi/" target="_blank" alt="kültür sanat akademi">Facebook</a></li>
            <li><a href="https://www.instagram.com/kultur.sanatakademi/" target="_blank" alt="kültür sanat akademi">Instagram</a></li>
            <li><a href="https://www.linkedin.com/company/kultur-sanat-akademi/" target="_blank" alt="kültür sanat akademi">LinkedIN</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {
      logoPath: require("@/assets/images/logo.png"),
      kategoriList: [],
      egitmenList: [],
      egitimlerList: [],
    };
  },
  mounted() {
    this.onKategoriDoldur();
    this.onEgitmenDoldur();
    this.onEgitimDoldur();
  },
  methods: {
    onKategoriDoldur() {
      this.$axios
        .get("/api/dashboard/get-categories?count=10")
        .then((response) => {
          response.data.forEach((element) => {
            this.kategoriList.push(element);
          });
        })
        .catch((error) => {
          console.log("onKategoriDoldur Error :>> ", error);
        });
    },
    onEgitmenDoldur() {
      this.$axios
        .get("/api/dashboard/get-teachers?count=10")
        .then((response) => {
          response.data.forEach((element) => {
            this.egitmenList.push(element);
          });
        })
        .catch((error) => {
          console.log("onEgitmenDoldur Error :>> ", error);
        });
    },
    onEgitimDoldur() {
      this.$axios
        .get("/api/dashboard/get-educations?count=10")
        .then((response) => {
          response.data.forEach((element) => {
            this.egitimlerList.push(element);
          });
        })
        .catch((error) => {
          console.log("onLoadTeachers Error :>> ", error);
        });
    },
  },
};
</script>

<style scoped>
.footer {
  /* padding: 1% 20%; */
  -webkit-box-shadow: 0px -5px 5px 0px rgba(112, 112, 112, 1);
  -moz-box-shadow: 0px -5px 5px 0px rgba(112, 112, 112, 1);
  box-shadow: 0px -5px 5px 0px rgba(112, 112, 112, 1);
  background-color: #202020 !important;
  color: white !important;
}
.footer-row-item {
  /* border: 1px solid red; */
}
.footer-row-title {
  color: rgb(155, 155, 155);
  font-weight: 600;
  font-size: 14px;
}
.footer-row-item ul {
  list-style: none;
  padding: 0 5px;
  line-height: 1.8;
  font-weight: normal;
}

.footer-row-item ul li {
  font-size: 14px;
  display: list-item;
  text-align: -webkit-match-parent;
}

.footer-row-item ul li a {
  text-decoration: none !important;
}
</style>
